import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GinkgoToken } from '../Models';


export const ACCESS_TOKEN_KEY: string = 'access_token_local';
export const REFRESH_TOKEN_KEY: string = 'refresh_token_local';

const initialState: GinkgoToken = {
  accessToken: localStorage.getItem(ACCESS_TOKEN_KEY) || '',
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY) || '',
}

export const ginkgoTokenSlice = createSlice({
  name: 'ginkgoToken',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<GinkgoToken>) => {
      if (!action.payload) {
        console.error('set token failed, payload is null or empty.')
        return;
      }
      const {accessToken, refreshToken} = action.payload;
      if (!accessToken || !refreshToken) {
        console.error('set token failed, accessToken and/or refreshToken is null or empty.')
        return;
      }

      state.accessToken = accessToken
      state.refreshToken = refreshToken
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },
    clearToken(state) {
      state.accessToken = ''
      state.refreshToken = ''
      localStorage.removeItem(ACCESS_TOKEN_KEY)
      localStorage.removeItem(REFRESH_TOKEN_KEY)
    }
  },
})

export const { setToken, clearToken } = ginkgoTokenSlice.actions
export const ginkgoTokenSliceName = ginkgoTokenSlice.name
export default ginkgoTokenSlice.reducer