import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { APP_GOOGLE_CLIENT_ID } from "./config";
import AppRouter from "./router";
import store from "./services/GinkgoDataStore";
import { Provider } from "react-redux";

export const App = () => {
  return (
    <Provider store={store}>
    <GoogleOAuthProvider clientId={APP_GOOGLE_CLIENT_ID}>
      <AppRouter />
    </GoogleOAuthProvider>
    </Provider>
  );
};

export default App;