import { configureStore, Store } from "@reduxjs/toolkit";
import { authApi, GinkgoToken } from "./AuthService";
import ginkgoTokenReducer, { ginkgoTokenSliceName } from "./reducers/UserAuthSlice";
import answerReducer, { answersSliceName, AnswersState } from "./reducers/AnswerSlice";
import { testCaseApi } from "./TestCaseService"



interface StoreType {
  ginkgoToken: GinkgoToken,
  answers: AnswersState
}

const store: Store<StoreType, any> = configureStore({
  reducer: {
    [ginkgoTokenSliceName]: ginkgoTokenReducer,
    [answersSliceName]: answerReducer,
    [authApi.reducerPath]: authApi.reducer,
    [testCaseApi.reducerPath]: testCaseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      testCaseApi.middleware
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;