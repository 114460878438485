export function getBaseUrl() {
    return "https://api-devo.ginkgo.health/api/v1";
    // return (process.env.NODE_ENV !== "development"
    //     ? "https://api-devo.ginkgo.health/api/v1"
    //     : "http://localhost:5000/api/v1")
};

export const DateFormat = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZoneName: 'short'
});