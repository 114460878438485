import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "./ApiUtil";

export interface GinkgoToken {
  accessToken: string,
  refreshToken: string
}

export interface AuthResponse {
  token: GinkgoToken
  userData:
  {
    lastAssessmentId: string,
    lastPrescriptionId: string,
    lastPrescriptionEndDate: string
  }
}

type AuthRequest = string

export const authApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: getBaseUrl() }),
  endpoints: (builder) => ({
    googleLogin: builder.mutation<AuthResponse, AuthRequest>({
      query: (credentials) => ({
        url: 'authentication/google',
        method: 'POST',
        headers: {
          Authorization: credentials
        },
      }),
    }),
    refreshToken: builder.mutation<GinkgoToken, GinkgoToken>({
      query: (token) => ({
        url: 'authentication/ginkgo',
        method: 'POST',
        headers: {
          Authorization: token.accessToken
        },
        body: token
      }),
    })
  }),
})

export const { useGoogleLoginMutation, useRefreshTokenMutation } = authApi