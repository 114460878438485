import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { RootState } from '../services/GinkgoDataStore';

const routesConfig = [
    {
        path: "/TestCases/:testCaseId",
        name: "TestCaseDetail",
        component: lazy(() => import('../views/TestCaseDetail')),
        auth: false
    },
    {
        path: "*",
        name: "Login",
        component: lazy(() => import('../views/LoginPage')),
        auth: false
    },
    {
        path: "/TestCases",
        name: "TestCases",
        component: lazy(() => import('../views/TestCaseList')),
        auth: false
    },

]

export default function AppRouters() {
    const ginkgoToken = useSelector((state: RootState) => state.ginkgoToken);
    const hasAuthed = !!ginkgoToken.accessToken
    return (
        <BrowserRouter>
            <Routes>
                {
                    routesConfig.map((route) => {
                        const {
                            path,
                            component: Component,
                            auth,
                            name
                        } = route;
                        const requireLogin = auth && !hasAuthed;
                        return (
                            <Route
                                key={name}
                                path={path}
                                element={
                                    requireLogin ?
                                        <Navigate to="/" replace /> :
                                        (
                                            <Suspense fallback={<div>loading...</div>}>
                                                <Component />
                                            </Suspense>
                                        )
                                }
                            />
                        )
                    })
                }
            </Routes>
        </BrowserRouter>
    )
}