import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { getAssessmentGroupByQuestionId } from "../../config/AssessmentOptions";
import { Answer, AssessmentDataPoint, NameValuePair } from "../Models";

export type AnswersState = {
  value: AssessmentDataPoint[]
};

const initialState: AnswersState = { value: [] };

export const AgeId = "fd4cc15e-3dc6-40c9-9b15-0f9d5d120946"
export const BirthYearId = "af9ad44c-6f22-4f5d-ad26-16cfd2f472d5"
export const SexAtBirthId = "ab6d9072-a298-4954-9287-f22d3f3ce44f"
export const WeightId = "cca2f629-db44-4ba5-8904-2f2091ed4f3c"
export const HeightId = "18f57667-7e89-4ad3-9db9-7c88c068452a"
export const TrainingDaysId = "c7531ac5-37c8-4bea-9d8f-0921322e5751"
export const AssetFilterId = "707af63d-06eb-446d-8851-5f61a80decb6"

export const AllAssetFilterValue = "1ed045bf-53cc-44ee-8243-68edc4338957"

export const AssetFilters : NameValuePair<string>[] = [
  {
    name: "All",
    value: AllAssetFilterValue
  },
  {
    name: "Captured",
    value: "bf35ab14-180c-4ee2-a928-3d071cb6f135"
  },
  {
    name: "Ready",
    value: "20cb89dd-9a55-47fd-95b9-9f9f51562270"
  }
];

export const UserBasicInfoQuestionIds = [
  AgeId, BirthYearId, SexAtBirthId, WeightId, HeightId, TrainingDaysId, AssetFilterId
];

export const UserInfoDataPointIndexBase = 100000;

function getAnswerIndex(answer: Answer, index: number) {
  if (answer.text) {
    const j = UserBasicInfoQuestionIds.indexOf(answer.text);
    if (j >= 0) {
      return UserInfoDataPointIndexBase + j;
    }
  }
  return index;
}

export const AnswersSlice = createSlice({
  name: "answers",
  initialState,
  reducers: {
    editAnswer: (state, action: PayloadAction<AssessmentDataPoint>) => {
      console.log("editAnswer");
      const { questionId, text, index, group } = action.payload;
      const newAnswers: AssessmentDataPoint[] = [];

      let newGroup = group;
      if (!newGroup && questionId) {
        const groupObject = getAssessmentGroupByQuestionId(questionId);
        newGroup = groupObject?.group || undefined;
      }

      let inserted = false;
      for (let dp of state.value) {
        if (dp.index === index || (questionId && dp.questionId === questionId)) {
          newAnswers.push({ ...dp, questionId, text, group: newGroup })
          inserted = true;
        } else {
          newAnswers.push(dp);
        }
      }

      if (!inserted) {
        const newIndex = index < 0 ? newAnswers.length : index;
        newAnswers.push({ questionId, text, group: newGroup, index: newIndex })
      }

      state.value = newAnswers;
    },
    clearAnswers: (state) => {
      console.log("clearAnswers");
      state.value = []
    },
    setAnswers: (state, action: PayloadAction<Answer[]>) => {
      state.value = action.payload.map((v, i) => ({
        ...v,
        index: getAnswerIndex(v, i),
        group: getAssessmentGroupByQuestionId(v.questionId)?.group
      }));
    },
    deleteAnswer: (state, action: PayloadAction<AssessmentDataPoint>) => {
      state.value = _.filter(state.value, a => a.index !== action.payload.index)
        .map((dp, i) => {
          if (dp.index < UserInfoDataPointIndexBase) {
            dp.index = i;
          }
          return dp;
        });
    },
  },
});

export const { editAnswer, clearAnswers, deleteAnswer, setAnswers } = AnswersSlice.actions;
export const answersSliceName = AnswersSlice.name;
export default AnswersSlice.reducer;