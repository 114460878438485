import { EnumAnswerValue } from "../services/Models";

export type AnswerType = 'bool' | 'number' | 'text' | "10 point rating" | "3 point rating" | "enum";

export interface AssessmentQuestion {
    question: string;
    questionId: string;
    answerType: AnswerType;
    answerValues?: EnumAnswerValue[]
};

export interface AssessmentsGroup {
    group: string
    questions: AssessmentQuestion[]
}

export const EmptyQuestion: AssessmentQuestion = {
    question: "",
    questionId: "",
    answerType: 'bool'
}

export function getAssessmentGroupByName(groupName: string | null | undefined): AssessmentsGroup | undefined {
    if (!groupName) {
        return undefined;
    }
    for (let ao of AssessmentOptions) {
        if (ao.group === groupName) {
            return ao;
        }
    }
    return undefined;
}

export function getAssessmentGroupByQuestionId(questionId: string | null | undefined): AssessmentsGroup | undefined {
    if (!questionId) {
        return undefined;
    }
    for (let ao of AssessmentOptions) {
        for (let q of ao.questions) {
            if (q.questionId === questionId) {
                return ao;
            }
        }
    }
    return undefined;
}

export function getQuestionByQuestionId(
    questionId: string | null | undefined,
    groups: AssessmentsGroup[] = AssessmentOptions): AssessmentQuestion | undefined {

    if (!questionId) {
        return undefined;
    }

    const assessmentGroups: AssessmentsGroup[] = groups || AssessmentOptions;

    for (let ao of assessmentGroups) {
        for (let q of ao.questions) {
            if (q.questionId === questionId) {
                return q;
            }
        }
    }
    return undefined;
}

export const AssessmentOptions: AssessmentsGroup[] = [
    {
        "group": "Metabolic Health",
        "questions": [
            {
                "question": "Chronic Kidney Disease",
                "questionId": "d3304aaa-411c-46bd-b479-c38da0d07f30",
                "answerType": "bool"
            },
            {
                "question": "Have you received dialysis?",
                "questionId": "ed6c5043-d5e5-47d2-93a1-df278defbbb0",
                "answerType": "bool"
            },
            {
                "question": "Are you receiving a transplant?",
                "questionId": "ed799932-8662-4cee-a9a9-d81ca3afd7a3",
                "answerType": "bool"
            },
            {
                "question": "Hypertension",
                "questionId": "d0f2d573-e8f3-415d-be59-0d9778ef3590",
                "answerType": "bool"
            },
            {
                "question": "Hyperlipidemia and Dyslipidemia",
                "questionId": "d0205ef0-c559-4874-8965-43f8475c5b69",
                "answerType": "bool"
            },
            {
                "question": "Metabolic Syndrome",
                "questionId": "370a008c-0d31-4c8d-9964-9319d706e7dc",
                "answerType": "bool"
            },
            {
                "question": "Type 1 Diabetes",
                "questionId": "7aef764b-3775-4e3f-854e-2133057b5c65",
                "answerType": "bool"
            },
            {
                "question": "Type 2 Diabetes",
                "questionId": "948b8655-d7da-4a8b-8b8e-41e278b9042b",
                "answerType": "bool"
            },
            {
                "question": "Metabolic Health - Other",
                "questionId": "374de163-bfb7-4072-9905-a8a93208b3a0",
                "answerType": "text"
            },
            {
                "question": "Heart or Vascular conditions",
                "questionId": "9a247663-6039-41a7-bb86-71f319808cbe",
                "answerType": "bool"
            },
            {
                "question": "Complications affecting your eyes or kidneys",
                "questionId": "5471b72a-7fbe-4238-b43e-e4cd387d3e08",
                "answerType": "bool"
            },
            {
                "question": "The sensation in your toes and feet",
                "questionId": "cdb1b338-3946-4e8b-8433-93d28457263c",
                "answerType": "bool"
            },
            {
                "question": "Retinopathy",
                "questionId": "da8f2f3e-e49c-4bf8-9061-bab275589829",
                "answerType": "bool"
            }
        ]
    },
    {
        "group": "Cardiovascular Health",
        "questions": [
            {
                "question": "Abnormality of heart rhythm",
                "questionId": "eebbc72b-1c7b-45b5-a06c-359a2f4db702",
                "answerType": "bool"
            },
            {
                "question": "Coronary Artery Disease",
                "questionId": "e607fee4-3c48-4f10-b1a7-7821e384183b",
                "answerType": "bool"
            },
            {
                "question": "Chronic Heart Failure",
                "questionId": "8ddd64bc-5668-4eff-825a-c184939b7b9d",
                "answerType": "bool"
            },
            {
                "question": "Peripheral Artery Disease",
                "questionId": "209366f6-2b62-49bb-aa99-abd09b25d227",
                "answerType": "bool"
            },
            {
                "question": "Do you feel any leg pain or discomfort when walking?",
                "questionId": "1bcfaf1f-6fde-48bc-9173-3ab0596d0684",
                "answerType": "bool"
            },
            {
                "question": "Cardiovascular Health - Other",
                "questionId": "c614bc54-41fd-4ed5-b802-65d316b8538d",
                "answerType": "text"
            }
        ]
    },
    {
        "group": "Respiratory Health",
        "questions": [
            {
                "question": "Asthma",
                "questionId": "15fd5067-b9fb-4700-9139-6c1a450afb35",
                "answerType": "bool"
            },
            {
                "question": "Chronic Obstructive Pulmonary Disease",
                "questionId": "fcef1520-dbfb-4666-ad5d-ace9de2720e7",
                "answerType": "bool"
            },
            {
                "question": "Cystic Fibrosis",
                "questionId": "22493bf2-e4af-4486-9dd9-ddb5660ca273",
                "answerType": "bool"
            },
            {
                "question": "Pulmonary Hypertension",
                "questionId": "742a7dde-5a3f-4f86-a87f-723f3cb34174",
                "answerType": "bool"
            },
            {
                "question": "Respiratory Health - Other",
                "questionId": "dd2613da-6a32-4203-93d0-8494a9896c51",
                "answerType": "text"
            }
        ]
    },
    {
        "group": "Immune Health",
        "questions": [
            {
                "question": "Autoimmune Disorder",
                "questionId": "2db99b0b-f69c-4e9b-ad3f-4ddbd71f2ac2",
                "answerType": "bool"
            },
            {
                "question": "Cancer",
                "questionId": "d46bcfef-8288-44fe-a015-4f5f763809fa",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Are you currently receiving treatment?",
                "questionId": "48dede34-2dea-4c93-914c-af6b0724f3ec",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Breast",
                "questionId": "6fda8fc1-1a4f-4be2-b872-a7134a156257",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Bronchogenic / Lung",
                "questionId": "91cc9b58-7108-4354-8ab4-8e2929dd29e9",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Head / Neck",
                "questionId": "ab24f01c-bf52-40d9-9527-07d9a7e8889f",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Metastatic / Pelvis, legs, or spine",
                "questionId": "9a0e07c6-661f-4220-a8c9-28ef12aa7a57",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Multiple myeloma",
                "questionId": "37cc979b-7f8f-4d5a-a91c-c68bd4c59a7d",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Ovarian",
                "questionId": "f63f5e70-1b84-4802-a14d-1965056832ca",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Prostate",
                "questionId": "f2c5e496-cb8d-488c-b46c-b1429184de60",
                "answerType": "bool"
            },
            {
                "question": "Cancer - Skin",
                "questionId": "930d1cc7-042d-476f-8c20-e63f418d1c01",
                "answerType": "bool"
            },
            {
                "question": "Human Immunodeficiency Virus",
                "questionId": "77727842-332e-4d12-ba1c-a740a21ac2ef",
                "answerType": "bool"
            },
            {
                "question": "Immune Health - Other",
                "questionId": "1b51e04b-f8cf-4239-8958-366e4552d9d8",
                "answerType": "text"
            }
        ]
    },
    {
        "group": "Bone & Joint Health",
        "questions": [
            {
                "question": "Toes",
                "questionId": "5c0a552b-bb20-4643-b05d-d0b558c2f615",
                "answerType": "bool"
            },
            {
                "question": "Ankles",
                "questionId": "fab151f0-861e-480e-ae60-57f7d52deb07",
                "answerType": "bool"
            },
            {
                "question": "Knees",
                "questionId": "3762c937-af79-4cfa-99b7-67ace413fa91",
                "answerType": "bool"
            },
            {
                "question": "Hips",
                "questionId": "3088691a-a6b4-4ee3-b6ec-b0269b8d4245",
                "answerType": "bool"
            },
            {
                "question": "Low back",
                "questionId": "a65567cd-2e59-4b7f-aa2a-85dbc2f373c1",
                "answerType": "bool"
            },
            {
                "question": "Mid-back",
                "questionId": "3861eacc-f069-4886-bd1b-ae5dda5cfd89",
                "answerType": "bool"
            },
            {
                "question": "Neck",
                "questionId": "dda62774-6c24-4ed1-b297-517755abdd6d",
                "answerType": "bool"
            },
            {
                "question": "Shoulders",
                "questionId": "9c7023bc-fc1e-4737-a848-2860d2b54a40",
                "answerType": "bool"
            },
            {
                "question": "Elbows",
                "questionId": "2c0f27f8-83e1-497f-9de5-74c5f100908a",
                "answerType": "bool"
            },
            {
                "question": "Wrists",
                "questionId": "19c94c94-f5a1-41bd-a752-020b35442e73",
                "answerType": "bool"
            },
            {
                "question": "Fingers",
                "questionId": "e461903a-45f2-4889-8e49-d089598a619d",
                "answerType": "bool"
            },
            {
                "question": "Osteoporosis",
                "questionId": "522dd035-3e5e-4ff8-84f9-b194cae547d1",
                "answerType": "bool"
            },
            {
                "question": "Bone & Joint Health - Other",
                "questionId": "eb98bc43-0e98-444b-9bbd-6d17116dd06f",
                "answerType": "text"
            }
        ]
    },
    {
        "group": "Neurologic Disorders",
        "questions": [
            {
                "question": "Lightheadedness with standing",
                "questionId": "17730d98-422e-4019-bd7e-560a575ce504",
                "answerType": "bool"
            },
            {
                "question": "Multiple Sclerosis",
                "questionId": "6fae9846-50cc-4e82-9e1c-8a32de53d2c0",
                "answerType": "bool"
            },
            {
                "question": "Are you comfortable using free weights (dumbbells or kettlebells)?",
                "questionId": "f7522cc6-e3be-4414-b204-df080e15de49",
                "answerType": "bool"
            },
            {
                "question": "Neuropathy",
                "questionId": "1b244312-b83f-46d3-ad0c-aaefdf27d873",
                "answerType": "bool"
            },
            {
                "question": "Parkinson's Disease",
                "questionId": "4e9039ec-c7ee-41ec-9243-013e4ebdf594",
                "answerType": "bool"
            },
            {
                "question": "Do you get dizzy with sudden changes in position?",
                "questionId": "f33c33e2-c71a-48e8-a08d-529efa7c5abd",
                "answerType": "bool"
            },
            {
                "question": "Stroke",
                "questionId": "4a514876-a395-4dbf-8b89-3b43e566bb80",
                "answerType": "bool"
            },
            {
                "question": "Seizures",
                "questionId": "b139c79b-1bd9-432d-a97f-6b62804af51e",
                "answerType": "bool"
            },
            {
                "question": "Vertigo",
                "questionId": "186a991d-4e89-40c7-b4dd-64a215941d29",
                "answerType": "bool"
            },
            {
                "question": "Neurologic Disorders - Other",
                "questionId": "37a9468c-e94f-4bdc-bfaa-0ea7245fe21a",
                "answerType": "text"
            }
        ]
    },
    {
        "group": "Learning Impairments & Mental health",
        "questions": [
            {
                "question": "Depression",
                "questionId": "dd1be58a-d7d5-4d4c-a03f-2755d8fec700",
                "answerType": "bool"
            },
            {
                "question": "Anxiety",
                "questionId": "bb5d0431-ae66-4d31-b715-34403faed487",
                "answerType": "bool"
            },
            {
                "question": "Psychotic disorder",
                "questionId": "bb981681-8d70-4fdb-944a-fcb48ec2d740",
                "answerType": "bool"
            },
            {
                "question": "Sleep disorder",
                "questionId": "c4182831-7f94-4837-815a-b2f7b90dc7fd",
                "answerType": "bool"
            },
            {
                "question": "Eating disorder",
                "questionId": "0ec26e69-268d-4639-8ac3-c9b499f83c77",
                "answerType": "bool"
            },
            {
                "question": "Alzheimer's and other Dementias",
                "questionId": "b07f2442-bc28-4d61-b348-a6c853fd9e70",
                "answerType": "bool"
            },
            {
                "question": "Intellectual disability",
                "questionId": "213739cf-ae15-4b1e-a294-e8a5712651ed",
                "answerType": "bool"
            },
            {
                "question": "Learning Impairments & Mental health - Other",
                "questionId": "947e16a3-ae8e-485d-bf8c-45f0b2e46a39",
                "answerType": "text"
            }
        ]
    },
    {
        "group": "Pelvic Floor & Pregnancy",
        "questions": [
            {
                "question": "If you are pregnant, how far along are you?",
                "questionId": "c4a8b328-98e1-40b3-a09f-ace967f34de8",
                "answerType": "number"
            },
            {
                "question": "Diastasis recti (abdominal separation)",
                "questionId": "cc03c2bf-5c4d-4c7d-8dc5-96771e6c489e",
                "answerType": "bool"
            },
            {
                "question": "Urinary incontinence (leakage)",
                "questionId": "047ab9e9-dbec-4500-8127-86b4b21a0f6d",
                "answerType": "bool"
            },
            {
                "question": "Pelvic organ prolapse",
                "questionId": "b4c58192-f201-4262-98d1-1ed19ed3321d",
                "answerType": "bool"
            }
        ]
    },
    {
        "group": "Balance Ability",
        "questions": [
            {
                "question": "Mobility Scooter",
                "questionId": "18e2e3e7-246f-4125-8c12-dfc44d2bc6c2",
                "answerType": "bool"
            },
            {
                "question": "Wheelchair",
                "questionId": "26715526-8c9b-4d11-960d-76b387c13775",
                "answerType": "bool"
            },
            {
                "question": "Walker",
                "questionId": "b2ee2909-cb9f-46d2-b71f-f07cc68b432a",
                "answerType": "bool"
            },
            {
                "question": "Cane",
                "questionId": "7912c696-99b2-436e-bda3-a9ae6894404a",
                "answerType": "bool"
            },
            {
                "question": "How many falls have you had in the last year?",
                "questionId": "b76f265c-5d2f-4893-8559-653bdb35525e",
                "answerType": "number"
            }
        ]
    },
    {
        "group": "Injury Report",
        "questions": [
            {
                "question": "How many months has this been an issue?(Toes)",
                "questionId": "e5c6d5e8-b552-4ada-abde-d22d19bf407b",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Toes)",
                "questionId": "c2a37a4c-2533-4e63-b894-5f743b1fc9e6",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Ankles)",
                "questionId": "598562a8-9fc1-43fb-bdd2-1ff792d0c3c2",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Ankles)",
                "questionId": "32f00e63-97a0-4a99-b2a2-fb293071be94",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Knees)",
                "questionId": "1c16c963-fc23-4f08-a74c-77f783c7fefe",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Knees)",
                "questionId": "b7c0b8c2-049e-4b23-8198-60955a14fee3",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Hips)",
                "questionId": "704c857a-110a-4b4e-9275-b70eccf368a3",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Hips)",
                "questionId": "06d360a3-e5eb-4686-b8a1-18e88516513a",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Low-back)",
                "questionId": "eb8f1ac5-1411-4f45-b583-44689f7e1d7a",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Low-back)",
                "questionId": "349e19de-1378-4762-8139-c63ac8cc4029",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Mid-back)",
                "questionId": "6d89841b-12d9-4eaa-80cf-c096d19621c5",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Mid-back)",
                "questionId": "e9335911-a5bf-40ae-bf06-86d7323b0195",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Neck)",
                "questionId": "6039aa05-c387-441e-b40b-8b0bb9b0b2d1",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Neck)",
                "questionId": "6d560b91-a9ec-4af4-a9b0-9116f17d398c",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Shoulders)",
                "questionId": "efc47e86-8965-43e4-81b8-321d006412ae",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Shoulders)",
                "questionId": "8913d771-5888-43c0-833e-b5b925505480",
                "answerType": "10 point rating"
            },
            {
                "question": "Does it hurt to raise your arms overhead?(Shoulders)",
                "questionId": "435afb84-94b5-44b6-a858-78ec9ad4759b",
                "answerType": "bool"
            },
            {
                "question": "How many months has this been an issue?(Elbows)",
                "questionId": "db91889a-735e-452e-9935-d52d5910e392",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Elbows)",
                "questionId": "fb3a5115-0961-4ed9-82a2-88d81e259f86",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Wrists)",
                "questionId": "d16beddf-2dc7-42a9-9021-e7a0133f199b",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Wrists)",
                "questionId": "32de76df-aadd-4cbe-8049-ed2fb83a147a",
                "answerType": "10 point rating"
            },
            {
                "question": "How many months has this been an issue?(Fingers)",
                "questionId": "812731b8-a8c4-4598-a937-5de9f43a7b4d",
                "answerType": "number"
            },
            {
                "question": "Please drag the slider to indicate how this impacts your daily life(Fingers)",
                "questionId": "37b58655-f5e0-450e-93a7-170632fa0e69",
                "answerType": "10 point rating"
            }
        ]
    },
    {
        "group": "Physical Capacity",
        "questions": [
            {
                "question": "What is your experience level with weight lifting?",
                "questionId": "6f111bf3-a2ab-4468-b4b1-52181f86b179",
                "answerType": "enum",
                "answerValues": [
                    {
                        name: "0-6 months",
                        value: "0079d2cc-4358-4c9f-ae67-71b0db03a6dd"
                    }, {
                        name: "6-12 months",
                        value: "cc6f1bf7-c3bf-4583-83a5-cf6d69cddce0"
                    }, {
                        name: "1-4 years",
                        value: "246ae30e-3eb1-469a-a715-7e892326c22f"
                    }, {
                        name: "4+ years",
                        value: "f808128c-1982-44ac-8c38-8da7f529cc43"
                    }
                ]
            },
            {
                "question": "What is your experience level with cardio (swimming, running, cycling etc)?",
                "questionId": "e003ee93-4410-403a-8e63-f04d6090fd95",
                "answerType": "enum",
                "answerValues": [
                    {
                        name: "0-6 months",
                        value: "1d538609-532f-4884-b7e7-40000b863fa6"
                    }, {
                        name: "6-12 months",
                        value: "bc44fc64-656f-48d0-86a5-c52e758c7df7"
                    }, {
                        name: "1-4 years",
                        value: "3e6e09a6-347a-4fa2-9eb4-3d39a8d2c25c"
                    }, {
                        name: "4+ years",
                        value: "bf6f34f6-4631-4157-a515-cf07ef40a780"
                    }
                ]
            }
        ]
    },
    {
        "group": "Sleep",
        "questions": [
            {
                "question": "On average, how long do you sleep each night?",
                "questionId": "19c7c241-2754-4dc2-b962-c648e94f46f1",
                "answerType": "number"
            },
            {
                "question": "How would you rate your sleep quality?",
                "questionId": "a4bf59f3-0a3a-4b57-8158-4a877c119cf1",
                "answerType": "3 point rating"
            }
        ]
    },
    {
        "group": "Nutrition",
        "questions": [
            {
                "question": "How would you rate your diet?",
                "questionId": "19c7c241-2754-4dc2-b962-c648e94f46f1",
                "answerType": "3 point rating"
            },
            {
                "question": "Would you like to learn more about nutrition?",
                "questionId": "a4bf59f3-0a3a-4b57-8158-4a877c119cf1",
                "answerType": "bool"
            }
        ]
    },
    {
        "group": "Smoking",
        "questions": [
            {
                "question": "How many cigarettes do you smoke per day on average?",
                "questionId": "6e3e2372-4b45-4382-94fb-a6bcef12a244",
                "answerType": "number"
            }
        ]
    },
    {
        "group": "Alcohol",
        "questions": [
            {
                "question": "How many standard drinks do you consume per week on average?",
                "questionId": "98edf223-6e83-456a-8b5a-5b422319135e",
                "answerType": "number"
            }
        ]
    },
    {
        "group": "Available Equipment",
        "questions": [
            {
                "question": "Dumbbells",
                "questionId": "d62f2af2-8255-434a-8cf2-ce8e236d70b1",
                "answerType": "bool"
            },
            {
                "question": "Kettlebell",
                "questionId": "e87b5560-8ef9-4069-b5b1-ce68648857ab",
                "answerType": "bool"
            },
            {
                "question": "Sliders",
                "questionId": "63966627-32f2-4a88-a31d-45d9cb6dca20",
                "answerType": "bool"
            },
            {
                "question": "Mini-band",
                "questionId": "a3530b46-565c-4b62-afa2-4dd1cbd4de59",
                "answerType": "bool"
            },
            {
                "question": "Theraband",
                "questionId": "dc1ef01b-951d-49fe-935d-41799e6d56eb",
                "answerType": "bool"
            },
            {
                "question": "Foam Rollers",
                "questionId": "6060bea2-5bfe-4c0a-b723-eaf9633efa02",
                "answerType": "bool"
            },
            {
                "question": "Swiss ball",
                "questionId": "0eb20fc5-6c66-45bb-b549-a2a00b619b86",
                "answerType": "bool"
            },
            {
                "question": "Aerobic Step",
                "questionId": "5d9f214b-1c2d-4471-98a5-ca9b9acd70e8",
                "answerType": "bool"
            },
            {
                "question": "Medicine Ball",
                "questionId": "92833f2f-cabc-4cc0-91d6-9cc6969273b2",
                "answerType": "bool"
            },
            {
                "question": "Massage Ball",
                "questionId": "4c2a8c56-5fd5-4ef7-b4f8-178069b4ec84",
                "answerType": "bool"
            },
            {
                "question": "Towel",
                "questionId": "4ca39d7b-f4ea-44eb-91ce-a08285d7357b",
                "answerType": "bool"
            },
            {
                "question": "Book",
                "questionId": "1d7d2b9d-3e69-46d9-99e6-2162962202ea",
                "answerType": "bool"
            },
            {
                "question": "Jump Rope",
                "questionId": "39abd9e4-ffb7-4dfc-a874-27b19fdad657",
                "answerType": "bool"
            },
            {
                "question": "Soccer Ball",
                "questionId": "59b91d0f-1092-4452-b9a6-9ea007448eaf",
                "answerType": "bool"
            }
        ]
    },
    {
        "group": "Functional Test Raw Data",
        "questions": [
            {
                "question": "Wall March",
                "questionId": "c97516d6-9271-4e23-8182-841dcd5b9a47",
                "answerType": "number"
            },
            {
                "question": "Single Leg Balance",
                "questionId": "8bc7b2ed-96bf-4f80-988f-7b94cf33cc0b",
                "answerType": "number"
            },
            {
                "question": "Wall Sit",
                "questionId": "476b53c1-18be-4b2f-b934-fa56945a1292",
                "answerType": "number"
            },
            {
                "question": "Repeated Sit-to-Stand",
                "questionId": "2307e3a0-b916-4fca-9a68-ad4881548926",
                "answerType": "number"
            }
        ]
    },
    {
        "group": "Functional Test Score",
        "questions": [
            {
                "question": "Wall March",
                "questionId": "c2968f4c-bba9-4771-8b36-650ff872cabf",
                "answerType": "3 point rating"
            },
            {
                "question": "Single Leg Balance",
                "questionId": "61d34f93-35fd-4551-8d65-b5357293389e",
                "answerType": "3 point rating"
            },
            {
                "question": "Wall Sit",
                "questionId": "3e603616-a34b-4cc0-bafa-9a0ea878c2bb",
                "answerType": "3 point rating"
            },
            {
                "question": "Repeated Sit-to-Stand",
                "questionId": "c776a5af-5ab2-4c31-9aec-06d17e143c03",
                "answerType": "3 point rating"
            }
        ]
    },
    {
        "group": "Activities",
        "questions": [
            {
                "question": "total active minutes in previous week",
                "questionId": "92c0a2e1-62a9-4ede-9643-6be2b3c3ac08",
                "answerType": "number"
            }
        ]
    }
];