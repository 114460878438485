import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "./ApiUtil";
import store from "./GinkgoDataStore";
import {
  CreateTestCaseRequest, GetTestCasesResponse, CreateTestCaseResponse,
  GetTestCaseRequest, GetTestCaseResponse, SaveAssessmentRequest,
  SaveAssessmentResponse, Prescription, CloneTestCaseRequest
} from "./Models";

export const testCaseApi = createApi({
  reducerPath: "testCaseAPI",
  baseQuery: fetchBaseQuery({ baseUrl: getBaseUrl() }),
  endpoints: (builder) => ({
    getTestCases: builder.query<GetTestCasesResponse, void>({
      query: () => ({
        url: 'testing-tools/academic/test-cases',
        method: 'GET',
        headers: {
          "Authorization": `${store.getState().ginkgoToken.accessToken}`
        }
      }),
    }),
    getTestCase: builder.query<GetTestCaseResponse, GetTestCaseRequest>({
      query: (testCaseId) => ({
        url: `testing-tools/academic/test-cases/${testCaseId}`,
        method: 'GET',
        headers: {
          Authorization: `${store.getState().ginkgoToken.accessToken}`
        }
      }),
    }),
    createTestCase: builder.mutation<CreateTestCaseResponse, CreateTestCaseRequest>({
      query: (request: CreateTestCaseRequest) => ({
        url: 'testing-tools/academic/test-cases',
        method: 'POST',
        headers: {
          Authorization: `${store.getState().ginkgoToken.accessToken}`
        },
        body: request
      }),
    }),
    saveAssessment: builder.mutation<SaveAssessmentResponse, SaveAssessmentRequest>({
      query: ({ testCaseId, assessmentId, answers }) => ({
        url: `testing-tools/academic/test-cases/${testCaseId}/assessments/${assessmentId}`,
        method: 'PUT',
        headers: {
          Authorization: `${store.getState().ginkgoToken.accessToken}`
        },
        body: { answers }
      })
    }),
    cloneAssessment: builder.mutation<CreateTestCaseResponse, CloneTestCaseRequest>({
      query: ({ testCaseId, name }) => ({
        url: `testing-tools/academic/test-cases/${testCaseId}/copy`,
        method: 'POST',
        headers: {
          Authorization: `${store.getState().ginkgoToken.accessToken}`
        },
        body: { name }
      })
    }),
    genRx: builder.mutation<Prescription, string>({
      query: (testCaseId) => ({
        url: `testing-tools/academic/test-cases/${testCaseId}/prescriptions`,
        method: 'POST',
        headers: {
          Authorization: `${store.getState().ginkgoToken.accessToken}`
        },
      })
    })
  }),
});

export const {
  useGetTestCaseQuery, useCreateTestCaseMutation,
  useCloneAssessmentMutation, useSaveAssessmentMutation,
  useGetTestCasesQuery, useGenRxMutation
} = testCaseApi;