import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import store from './services/GinkgoDataStore'
import { Provider } from 'react-redux'
import Modal from 'react-modal';

const RootElementId = 'root'

const container = document.getElementById(RootElementId);
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
Modal.setAppElement(`#${RootElementId}`);
